import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable, of, Subject, throwError, timer } from 'rxjs';
import {
  catchError,
  delayWhen,
  finalize,
  map,
  mergeMap,
  retryWhen,
  tap,
} from 'rxjs/operators';
import { isIsoDate, toDateObject } from '../util/date.util';
import { PlatformStateService } from '../services/platform-state.service';
import { environment } from 'src/environments/environment';
// import { RootInjectorGuard } from 'src/app/shared/gaurds';

const MAX_RETRIES = 3;

export interface getRequest {
  request_id?: string;
  type: string;
}

export interface HTTPError {
  error_id?: string;
  path?: string;
  response?: string;
  code?: string;
}

export interface HTTPFatalError extends HTTPError {
  fatal: boolean;
}

export interface HTTPResolvableError extends HTTPError {
  resolved?: boolean;
  retry?: boolean;
  cancelled?: boolean;
}

export function attemptRetry(max: number = this.MAX_RETRIES): any {
  let retries = max;

  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) =>
        errors.pipe(
          delayWhen(() => timer(2000)),
          mergeMap((error) => (retries-- > 0 ? of(error) : throwError(error)))
        )
      )
    );
}

export interface HTTPError {
  error_id?: string;
  path?: string;
  response?: string;
  code?: string;
}

export interface HTTPFatalError extends HTTPError {
  fatal: boolean;
}

export interface HTTPResolvableError extends HTTPError {
  resolved?: boolean;
  retry?: boolean;
  cancelled?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HttpClientRoot {
  dev = false;

  PRODENDPOINT = 'https://ap.fanmire.com/api/';
  TESTENDPOINT = environment.dev + '/api/';
  USER = 'users/';
  WALL = 'wall/';
  POST = 'feed/';
  INTEREST_LIST = 'interestlist/';

  MEDIA_UPLOAD = 'media/';
  MEDIA_JSON_UPLOAD = 'media/json/';
  CONTENT_UPLOAD = 'content/';

  PROFILE_PIC = 'profile/';
  COVER_PIC = 'cover/';

  SETTING = 'setting/';
  server: string;
  httpHead = new HttpHeaders();
  options: any;

  constructor(private HTTP: HttpClient) {
    this.httpHead.set('Content-Type', 'application/json');
    this.options = {
      headers: this.httpHead,
      reportProgress: true,
    };

    if (this.dev == true) {
      this.server = this.TESTENDPOINT;
    } else {
      this.server = this.PRODENDPOINT;
    }
  }

  $post(url: string, data: any): any {
    return this.HTTP.post(url, data, this.options).pipe(
      catchError((err) => {
        //console.log('caught server error and rethrowing', err);
        //Notify User of error
        //Todo: Http Error Service
        return throwError(err);
      }),
      // finalize(() => console.log("first finalize() block executed")),
      catchError((err) => {
        //console.log('caught rethrown error, providing fallback value');
        return of(null);
      })
      // finalize(() => console.log("second finalize() block executed"))
    );
  }

  $get(url: string, attemps?: number): Observable<any> {
    let reAttemp = this.HTTP.get(url, this.options).pipe(
      attemptRetry(3),
      catchError((err) => {
        //console.log('caught server error and rethrowing', err);
        return throwError(err);
      })
    );

    let call = this.HTTP.get(url, this.options).pipe(
      // tap(x => console.log(x))
      map((res) => {
        //console.log(res)
        this.traverseObject(res, this.naturalizeDates);
        return res;
        // this.naturalizeDates(res)
      }),
      catchError((err) => {
        //console.log('caught server error and rethrowing', err);
        //Notify User of error
        //Todo: Http Error Service
        return throwError(err);
      }),
      // finalize(() => console.log("first finalize() block executed")),
      catchError((err) => {
        //console.log('caught rethrown error, providing fallback value');
        return of(null);
      })
      // finalize(() => console.log("second finalize() block executed"))
    );

    return attemps ? reAttemp : call;
  }

  $put(url: string, data: any, headers?: HttpHeaders): any {
    let opts = this.options;
    if (headers) {
      opts.headers = headers;
      opts.reportProgress = true;
    }

    return this.HTTP.put(url, data, headers ? opts : this.options).pipe(
      catchError((err) => {
        //console.log('caught server error and rethrowing', err);
        //Notify User of error
        //Todo: Http Error Service
        return throwError(err);
      }),
      // finalize(() => console.log("first finalize() block executed")),
      catchError((err) => {
        //console.log('caught rethrown error, providing fallback value');
        return of(null);
      })
      // finalize(() => console.log("second finalize() block executed"))
    );
  }

  $delete(url: any): Observable<any> {
    let subject = new Subject();

    this.HTTP.delete(url).subscribe(
      (res) => {
        //Success
        subject.next(res);
        subject.complete();
      },
      (res) => {
        //Error
        subject.next(null);
        subject.complete();
      }
    );

    return subject.asObservable();
  }

  naturalizeBooleans(i: any): any {
    if (i == 0) return false;
    else if (i == 1) return true;
    else if (i) return i;
  }

  naturalizeDates(proterty: string, value: any): any {
    if (proterty.includes('date')) {
      value = toDateObject(value);
      // console.log('key:', proterty, 'object:', value)

      // return toDateObject(value)
    }
  }

  traverseObject(object: any, fn?: Function, key?: any) {
    if (object !== null && typeof object == 'object') {
      Object.entries(object).forEach(([key, value]) => {
        // console.log('is:', typeof value)
        // console.log('key:', key, 'object:', value)

        this.traverseObject(value, fn, key);
      });
    } else if (object) {
      // console.log(key)
      // console.log('is:', typeof object)
      // console.log(object)
      if (fn) {
        fn.apply(this, [key, object]);
      }
    }
  }
}
